<!-- 统一身份认证登录临时页面 -->
<template>
  <div />
</template>
<script>
import Cookies from 'js-cookie'
import { Toast } from 'vant'
export default {
  name: 'Transfer',
  components: {
    [Toast.name]: Toast
  },
  mounted() {
    /**
     *  将首页、待缴费、退费隐藏。之所以隐藏是适配迎新系统进入缴费平台，学宿费减免,
     *  首页项目是老生学宿费，新生学宿费定制页面
     *  订单不隐藏z
     */
    window.localStorage.setItem('showHeadFlag', false)
    this.handleGoToDkPayment()
  },
  methods: {
    handleGoToDkPayment() {
      const jsonData = this.$route.query.jsonData
      if (this.$route.query.goToURL === 'boundWechat') {
        window.localStorage.setItem('enterSource', 'wechat')
        window.localStorage.setItem('appId', JSON.parse(jsonData).appId)
        window.localStorage.setItem('openId', JSON.parse(jsonData).openId)
        this.$router.push({ path: '/home' })
      }
      const token = this.$route.query.token
      if (token === undefined || token === '') {
        Toast('传入参数无效！')
        // this.$messageOK('error', '传入参数无效！')
        this.$router.push({ path: this.redirect || '/login' })
        return false
      }
      this.$store.dispatch('user/refreshToken', token).then(() => {
        Cookies.set('qy_reimbursement_token', this.$route.query.token)
        this.$store.dispatch('user/getInfo')
        this.$store.state.user.accountNum = JSON.parse(jsonData).idserial
        window.localStorage.setItem('accountNum', JSON.parse(jsonData).idserial)
        if (this.$route.query.goToURL === 'homeCoWechat') {
          window.localStorage.setItem('enterSource', 'CoWechat')
          window.localStorage.setItem('agentId', JSON.parse(jsonData).agentId)
          this.$router.push({ path: '/home' })
        } else if (this.$route.query.goToURL === 'homeWechat') {
          window.localStorage.setItem('enterSource', 'wechat')
          window.localStorage.setItem('appId', JSON.parse(jsonData).appId)
          this.$router.push({ path: '/home' })
        }
        this.$router.push({ name: 'openapituitionAndDorm_detail', params: { jsonData: jsonData, returnUrl: JSON.parse(jsonData).returnUrl }})
      })
    }
  }
}
</script>
